<template>
  <div class="historyincome">
    <img :src="historyBg" class="historyBg-img">
    <div class="title">
      <div>累计收入（元）</div>
      <div>{{accumulationIncome}}</div>
    </div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="content-wrap" v-for="(item, index) in dataList" :key="index">
          <div class="content-item">
            <div class="content-title flex">
              <span class="greyColor">{{item.dateMonth}}</span>
              <span class="blackColor">{{item.monthServiceFee}}元</span>
            </div>
            <van-divider />
            <div class="content-content">
              <div class="flex"><span>日均（元）</span><span>{{item.monthDayAvgAmt}}</span></div>
              <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&item.stockYearMonthDayAvg"><span>存量业绩月日均（元）</span><span>{{item.stockYearMonthDayAvg}}</span></div>
              <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&item.oneYearMonthDayAvg"><span>1年（不含）以下新增月日均（元）</span><span>{{item.oneYearMonthDayAvg}}</span></div>
              <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&item.twoYearMonthDayAvg"><span>1及2年定期新增月日均（元）</span><span>{{item.twoYearMonthDayAvg}}</span></div>
              <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&item.threeYearMonthDayAvg"><span>3年及以上新增月日均（元）</span><span>{{item.threeYearMonthDayAvg}}</span></div>
              <div class="flex" v-if="this.province != '河南省' &&item.threeYearMonthDayAvg"><span>服务费率</span><span>{{item.monthDayRateStr}}</span></div>
              <div class="flex"><span>基础服务费（元）</span><span class="blackColor">{{item.basicServiceFee}}</span></div>
              <div class="flex"><span>考核服务费（元）</span><span class="blackColor">{{item.settleFee}}</span></div>
              <div class="flex"><span>激励（元）</span><span class="blackColor">{{item.incentiveAmt}}</span></div>
              <div class="flex" v-if="item.bankCardNo"><span>收款账号</span><span>{{item.bankCardNo | cardFilter}}</span></div>
              <div class="flex" v-if="item.settleMethod"><span>结算方式</span><span>{{item.settleMethod === 'INVOICE' ?'开票':item.settleMethod ==='REMUNERATION'?'劳务报酬':'提现'}}</span></div>
              <img v-if="item.payStatus == '1'" :src="paid" class="flag-img">
              <img v-else :src="item.status == 1 ? confirm : unconfirm" class="flag-img">
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>


    </div>
  </div>
</template>

<script>
import {getIncomeList} from '@/utils/api'
import common from '@/utils/common'
export default {
  data() {
    return{
      historyBg: require('@/assets/img/stationagent/historyBg.png'),
      confirm: require('@/assets/img/stationagent/confirm.png'),
      unconfirm: require('@/assets/img/stationagent/unconfirm.png'),
      paid: require('@/assets/img/stationagent/paid.png'),
      isLoading: false,
      loading: false,
      finished: false,
      province: '',
      pageIndex: 1,
      pageSize: 10,
      dataList:[],
      accumulationIncome: 0,
      tempToken: '',
    }
  },
  filters:{
    cardFilter(val) {
      return val.replace(/\s/g,'').replace(/(\d{4})(?=\d)/g,"$1 ")
    }
  },
  mounted(){
    this.tempToken = this.$route.query.jumpFrom !=undefined ? this.$route.query.token : common.getItem('wtToken')
  },
  methods:{
    onRefresh() {
      this.isLoading = false
      this.dataList = []
      this.pageIndex = 1
      this.togetIncomeList()
    },
    onLoad () {
      this.togetIncomeList()
    },
    togetIncomeList(){
      getIncomeList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        token: this.tempToken
      }).then(res=>{
        this.loading = false;
        this.finished = !res.data.data.hasNextPage;
        this.province = res.data.province
        if (this.pageIndex == 1) {
          this.dataList = res.data.data.list;
        } else {
          this.dataList = this.dataList.concat(res.data.data.list);
        }
        if (!this.finished) {
          this.pageIndex++;
        }
        this.accumulationIncome = res.data.accumulationIncome
      })
    },
  }
}
</script>

<style lang="less" scoped>
.historyincome{
  background: #F7F8F9;
  padding: 0.3rem;
  min-height: 100%;
  position: relative;
  .historyBg-img{
    width: 100%;
  }
  .title{
    position: absolute;
    left:50%;
    top: 0.78rem;
    color: #fff;
    transform: translateX(-50%);
    text-align: center;
    div{
      &:nth-child(1){
        font-size: 0.28rem;
      }
      &:nth-child(2){
        font-size: 0.8rem;
      }
    }
  }
  .flag-img{
    width:1.46rem;
    height: 0.88rem;
    position: absolute;
    right:0;
    bottom:0;
  }
  .van-divider{
    margin: 0.1rem 0 0.2rem;
  }
  .content-item{
    background: #fff;
    border-radius: 0.16rem;
    box-shadow: 0px 12px 28px 0px rgba(63,124,83,0.07);
    margin-bottom: 0.4rem;
    padding: 0.36rem 0.3rem 0.6rem;
    position: relative;
    .greyColor{
      color: #9B9B9B;
    }
    .blackColor{
      color: #272829;
    }
    .bold{
      font-weight: bold;
    }
    .content-title{
      justify-content: space-between;
      align-items: center;
      span{
        &:nth-child(1){
          font-size: 0.28rem;
        }
        &:nth-child(2){
          font-size: 0.4rem;
        }
      }
    }
    .content-content{
      div{
        font-size: 0.24rem;
        justify-content: space-between;
        color: #9B9B9B;
        margin-bottom: 0.08rem;
      }
    }
  }
}
</style>
